<template>
  <div class="my_modify_psd">
    <div class="warpper">
      <h2 class="title">修改密码</h2>
      <div class="form">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="140px"
          class="demo-ruleForm demo-dynamic">
          <!-- <el-form-item label="输入手机号" prop="pass">
                      <el-input type="numbers" v-model="ruleForm.numbers" maxlength="11" autocomplete="off" placeholder="请输入手机号"></el-input>
                    </el-form-item> -->
          <!-- <el-form-item label="输入手机验证码" prop="checkPass" class="special-input">
                      <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请输入验证码"></el-input>
                      <el-button>获取验证码</el-button>
                    </el-form-item> -->
          <el-form-item label="设置新密码" prop="pass">
            <el-input v-model.number="ruleForm.pass" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="checkPass">
            <el-input v-model.number="ruleForm.checkPass" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item-btns">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        let reg =
          /^(?=.*\d)(?=.*[a-zA-Z_])(?=.*[~!@#$%^&*()\-+={}[\]|\\:;"'<,>?/._])[\da-zA-Z~!@#$%^&*()\-+={}[\]|\\:;"'<,>?/._]{8,}$/;
        if (!value) {
          return callback(new Error("请输入密码"));
        } else if (!reg.test(value)) {
          return callback(new Error('密码需包含大小字母、数字、特殊符号且8-16位'));
        } else {
          return callback();
        }
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请确认密码"));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error("两次密码输入不一致!"));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          pass: "",
          checkPass: ""
        },
        rules: {
          pass: [{
            validator: validatePass,
            trigger: "blur",
            required: true
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: "blur",
            required: true
          }]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(async valid => {
          // console.log(valid);
          if (valid) {
            let params = {
              password: String(this.ruleForm.pass),
              repeatPassword: String(this.ruleForm.checkPass)
            };
            // console.log(params);
            let resData = await this.$Api.My.getChange_pwd(params);
            // console.log(resData);
            if (resData.data.code === 200 && resData.data.msg === '请求成功') {
              this.$notify({
                title: "成功",
                message: "密码修改成功",
                type: "success",
              });
              this.$refs[formName].resetFields();
            } else {
              this.$notify.error({
                title: "错误",
                message: '密码需包含大小字母、数字、特殊符号且8-16位'
              });
            }

          } else {
            this.$notify.error({
              title: "错误",
              message: '密码需包含大小字母、数字、特殊符号且8-16位'
            });
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  };
</script>

<style lang="less" scoped>
  .my_modify_psd {
    border-radius: 10px;

    background: #ffffff;

    .warpper {
      padding-left: 9px;
    }

    .title {
      border-bottom: 1px solid #f9f9f9;
      padding-left: 35px;
      line-height: 69px;

      font-size: 22px;
    }

    .form {
      padding-top: 35px;
      padding-left: 51px;
    }

    .el-input {
      max-width: 296px;
    }

    .special-input {
      .el-input {
        width: 178px;
        margin-right: 6px;
      }

      .el-button {
        border: 1px solid #508EF9;
        color: #508EF9;
      }
    }

    .el-form-item-btns {
      margin-left: 80px;
      margin-top: 64px;
      text-align: left;

      .el-button {
        font-size: 16px;
        padding: 0px 63px;

        &:first-child {
          color: #508EF9;
          border: 1px solid #508EF9;
        }

        &:last-child {
          margin-left: 80px;
          background-color: #508EF9;

          &:hover {
            background-color: rgba(80, 142, 249, 0.8);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .my_modify_psd {
      max-width: 660px;
      width: 660px;
    }
  }
</style>